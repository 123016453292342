import React, { useEffect, useState } from "react";
import { whatsAppNumberApp } from "../../constants/config";
import Images from "../../images";
import ProductEnquiryForm from "./ProductEnquiryForm";

function ProductName(props) {
  const [product, setProduct] = useState({});
  useEffect(() => {
    setProduct(props.product);
  }, [props]);
  let whatsAppNumber = whatsAppNumberApp;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="col-md-6 col-sm-12 mt-md-5 pt-5">
        <h1>{product.name && product.name}</h1>
        <p>
          {product.short_description && product.short_description.length > 610
            ? product.short_description.substring(0, 610) + "..."
            : product.short_description}{" "}
        </p>
        <div className="d-flex align-items-center flex-wrap m-n1">
          {product.artikelnumber ? (
            <span className="badge badge-success p-2 m-1">
              Artikelnumber : {product.artikelnumber}
            </span>
          ) : (
            ""
          )}
          {product.ean ? (
            <span className="badge badge-success p-2 m-1">
              OEM : {product.oem}
            </span>
          ) : (
            ""
          )}
          {product.ean ? (
            <span className="badge badge-success p-2 m-1">
              EAN : {product.ean}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex align-items-center mt-5">
          <button className="btn_fl mr-3" onClick={handleShow}>
            Get a Quote
          </button>
          <a className="btn_fl blue mr-3" href="" target="_blank">
            Quick Call
          </a>
          <a
            className="btn_fl wp"
            href={whatsAppNumber ? `http://wa.me/${whatsAppNumber}` : "#"}
            target="_blank"
          >
            <img className="img-fluid" src={Images.wp} alt="#" />
          </a>
        </div>
      </div>
      <ProductEnquiryForm
        show={show}
        setShow={setShow}
        dropdown={false}
        product={{ id: product.id, name: product.name }}
      />
    </>
  );
}

export default ProductName;
