import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Banner from "../components/products/banner/Banner";
import Filter from "../components/products/filters/Filter";
import ProductList from "../components/products/product/ProductList";
import getCategories from "../context/actions/getCategories";
import getProducts from "../context/actions/getProducts";
import { GlobalContext } from "../context/Provider";

function ProductsPage() {
  const { categoriesDispatch, categoriesState } = useContext(GlobalContext);
  const { productsDispatch, productsState } = useContext(GlobalContext);

  const { categoryId } = useParams();

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredBrand, setFilteredBrand] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState("");

  const history = useHistory();
  useEffect(() => {
    getCategories(history)(categoriesDispatch);
    getProducts(history)(productsDispatch);
    if (categoryId) {
      setFilteredCategories([parseInt(categoryId)]);
    } else {
      setFilteredCategories([]);
    }
  }, []);
  return (
    <>
      <Banner />
      <div className="product_page_content py-5">
        <div className="container">
          <div className="row">
            <Filter
              state={categoriesState}
              setFilterSearchValue={setFilterSearchValue}
              setFilteredCategories={setFilteredCategories}
              filteredCategories={filteredCategories}
              setFilteredBrand={setFilteredBrand}
            />
            <ProductList
              state={productsState}
              filterSearchValue={filterSearchValue}
              filteredCategories={filteredCategories}
              filteredBrand={filteredBrand}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsPage;
