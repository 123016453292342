export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_LOAD_SUCCESS = "CATEGORIES_LOAD_SUCCESS";
export const CATEGORIES_LOAD_ERROR = "CATEGORIES_LOAD_ERROR";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_LOAD_SUCCESS = "PRODUCTS_LOAD_SUCCESS";
export const PRODUCTS_LOAD_ERROR = "PRODUCTS_LOAD_ERROR";

export const ADD_ENQUIRY_LOAD = "ADD_ENQUIRY_LOAD";
export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_ERROR = "ADD_ENQUIRY_ERROR";
