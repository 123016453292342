import {
  PRODUCTS_LOADING,
  PRODUCTS_LOAD_ERROR,
  PRODUCTS_LOAD_SUCCESS,
} from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance";

export default (history) => (dispatch) => {
  dispatch({
    type: PRODUCTS_LOADING,
  });
  axiosInstance(history)
    .get("/api/v1/customer/get_all_products")
    .then((res) => {
      dispatch({
        type: PRODUCTS_LOAD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PRODUCTS_LOAD_ERROR,
        payload: err.response ? err.response.data : CONNECTION_ERROR,
      });
    });
};
