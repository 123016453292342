import { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Home from "./pages/Home";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ProductsPage from "./pages/ProductsPage";
import routes from "./routes/Routes";
import ScrollToTop from "./context/ScrollToTop";
import { useContext } from "react";
import { GlobalContext } from "./context/Provider";

function App() {
  // document.title = route.title || 'Impact';
  const { categoriesState } = useContext(GlobalContext);

  return (
    <div className="App">
      <Router basename="/">
        <ScrollToTop>
          <Header categoriesState={categoriesState} />
          <Switch>
            {/* {
              routes.map((route, index) => {
                <Route
                  key={index}
                  path={route.path}
                  exact
                  render={(props) => <route.component {...props} />}
                >
                </Route>
              })
            } */}
            <Route exact path="/" title="Home">
              <Home />
            </Route>
            <Route path="/about" component={AboutPage} />
            <Route path="/contact" component={ContactPage} />
            <Route exact path="/products" component={ProductsPage} />
            <Route path="/products/:categoryId" component={ProductsPage} />
            <Route
              path="/product_details/:productId"
              component={ProductDetailsPage}
            />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
