import React, { useState } from "react";
import { Link } from "react-router-dom";
import SkeltonLoader from "../../skeltonloader/skeltonLoader";
import ImageWithFallback from "../../core/ImageWithFallback";
function Products({
  state: {
    categories: { loading, error, data },
  },
}) {
  const skeltonLoader = () => {
    const loaderCount = 8;
    let myArray = [];
    for (let i = 0; i < loaderCount; i++) {
      myArray.push(<SkeltonLoader />);
    }
    return myArray;
  };
  return (
    <section className="products">
      <div className="container">
        <div className="row align-items-center mb-0 mb-sm-4 no-gutters">
          <div className="col">
            <h3>
              Products<span>Dedicated to excellence.</span>
            </h3>
          </div>
          <div className="col text-right">
            <Link className="btn_" to="/products">
              Explore Products
            </Link>
          </div>
        </div>
        <div className="row">
          {loading && (
            <>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 py-3">
                            <div class="bg-white  product">
                                <div class="shimmerBG media"></div>
                                <div class="p-10">
                                    <div class="shimmerBG title-line"></div>
                                </div>
                            </div>
                        </div> */}
            </>
          )}
          {data.status && data.category
            ? data.category.map((element, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="col-lg-3 col-md-4 col-sm-6 py-3"
                    >
                      <Link
                        to={{
                          pathname: `/products/${element.id}`,
                        }}
                        className="bg-white product w-100"
                      >
                        <div className="w-100 aspect-85-63">
                          <ImageWithFallback
                            className="w-100 h-100 object-fit-contain"
                            src={
                              element.image &&
                              data.category_image_base_url + element.image
                            }
                            alt={element.name || ""}
                          />
                        </div>
                        <strong className="p-4 d-block">{element.name}</strong>
                      </Link>
                    </div>
                  </>
                );
              })
            : skeltonLoader()}
        </div>
      </div>
    </section>
  );
}

export default Products;
