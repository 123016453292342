import React, { useState } from "react";

function Pagination(props) {
  const { setCurrentPage, currentPage, itemPerPage, filteredData } = props;
  const [pageRange, setPageRange] = useState(0); // To track the current set of pages being displayed
  let numberOfPages = [];

  if (filteredData) {
    for (let i = 1; i <= Math.ceil(filteredData.length / itemPerPage); i++) {
      numberOfPages.push(i);
    }
  }

  const loadPage = (e) => {
    const selectedPage = e.target.id;
    if (selectedPage === "next") {
      if ((pageRange + 1) * 5 < numberOfPages.length) {
        setPageRange(pageRange + 1);
      }
    } else if (selectedPage === "prev") {
      if (pageRange > 0) {
        setPageRange(pageRange - 1);
      }
    } else {
      setCurrentPage(Number(selectedPage));
    }
  };

  const startPage = pageRange * 5;
  const endPage = Math.min(startPage + 5, numberOfPages.length);

  const pages = numberOfPages.slice(startPage, endPage).map((item) => (
    <li key={item} id={item} onClick={loadPage}>
      {item}
    </li>
  ));

  return (
    <>
      {filteredData && filteredData.length > itemPerPage && (
        <ul className="pagination flex justify-content-start mt-5">
          {pageRange > 0 && (
            <li className="" id="prev" onClick={loadPage}>
              ‹
            </li>
          )}
          {pages}
          {endPage < numberOfPages.length && (
            <li className="" id="next" onClick={loadPage}>
              ›
            </li>
          )}
        </ul>
      )}
    </>
  );
}

export default Pagination;
