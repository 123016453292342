const Images = {
  logo: require("./assets/img/logo.png").default,
  logo_grey: require("./assets/img/logo_grey.png").default,
  logo_icon_grey: require("./assets/img/logo_icon_grey.png").default,
  impact: require("./assets/img/impact.jpg").default,
  whatsAppLogo: require("./assets/img/wp.svg").default,
  banner: require("./assets/img/banner.png").default,
  laser: require("./assets/img/laser.svg").default,
  printer: require("./assets/img/printer.svg").default,
  product: require("./assets/img/product_1.jpg").default,
  whoWe: require("./assets/img/who-we-are.jpg").default,
  avatar: require("./assets/img/avatar.svg").default,
  fb: require("./assets/img/fb.svg").default,
  tw: require("./assets/img/tw.svg").default,
  ig: require("./assets/img/ig.svg").default,
  in: require("./assets/img/in.svg").default,
  wp: require("./assets/img/wp.svg").default,
  about_banner: require("./assets/img/about_banner.jpg").default,
  about: require("./assets/img/about.jpg").default,
  like: require("./assets/img/like.svg").default,
  product_banner: require("./assets/img/product_banner.jpg").default,
  product_banner_1: require("./assets/img/product_banner_1.jpg").default,
  product_dummy_1: require("./assets/img/product_dummy_1.jpg").default,
  product_dummy: require("./assets/img/product_dummy.jpg").default,
  product_dummy_2: require("./assets/img/product_dummy_2.jpg").default,
};

export default Images;
