import {
  ADD_ENQUIRY_ERROR,
  ADD_ENQUIRY_LOAD,
  ADD_ENQUIRY_SUCCESS,
} from "../../constants/actionTypes";
import { CONNECTION_ERROR } from "../../constants/api";
import axiosInstance from "../../helpers/axiosInstance";

export default ({
    name: name,
    email: email,
    phone: phone,
    place: place,
    message: message,
    product: product,
    enquiry_message: enquiry_message,
  }) =>
  (dispatch) => {
    dispatch({
      type: ADD_ENQUIRY_LOAD,
    });

    axiosInstance()
      .post("api/v1/customer/add_enquiry", {
        name,
        email,
        phone,
        place,
        message,
        product,
        type: "enquiry",
        enquiry_message,
      })
      .then((res) => {
        console.log(`res`, res);
        dispatch({
          type: ADD_ENQUIRY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(`err`, err);
        dispatch({
          type: ADD_ENQUIRY_ERROR,
          payload: err.response ? err.response.data : CONNECTION_ERROR,
        });
      });
  };
