import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

function ProductDescription(props) {
  const { product_description } = props;
  const [description, setDescription] = useState("");
  useEffect(() => {
    setDescription(product_description);
  }, [product_description]);
  return (
    <>
      <div className="product_content mt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>About this item</h4>
              {parse(description)}
              {/* <p>Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Netus et malesuada fames ac turpis egestas maecenas pharetra convallis. Tellus integer feugiat scelerisque varius morbi. Sit amet nisl purus in mollis nunc sed. Vivamus at augue eget arcu dictum varius duis. Eros donec ac odio tempor orci dapibus. Dignissim enim sit amet venenatis urna cursus eget nunc. </p>
                            <ul className="list">
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                                <li>ac turpis egestas maecenas pharetra convallis posuere morbi leo urna iaculis eu non diam phasellus</li>
                            </ul> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDescription;
