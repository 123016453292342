import {
  ADD_ENQUIRY_ERROR,
  ADD_ENQUIRY_LOAD,
  ADD_ENQUIRY_SUCCESS,
} from "../../constants/actionTypes";

const enquiry = (state, { payload, type }) => {
  switch (type) {
    case ADD_ENQUIRY_LOAD: {
      return {
        ...state,
        enquiry: {
          ...state.enquiry,
          loading: true,
          error: null,
        },
      };
    }
    case ADD_ENQUIRY_SUCCESS: {
      return {
        ...state,
        enquiry: {
          ...state.enquiry,
          loading: false,
          data: payload,
        },
      };
    }
    case ADD_ENQUIRY_ERROR: {
      return {
        ...state,
        enquiry: {
          ...state.enquiry,
          loading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default enquiry;
