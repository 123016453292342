import React from "react";
import { Link } from "react-router-dom";
import Images from "../../../images";

function Banner() {
  return (
    <div className="head_comp">
      <div className="banner position-relative">
        <div className="banner_img">
          <img className="img-fluid" src={Images.banner} alt="#" />
        </div>
        <div className="banner_content">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>
                  Turning <span className="clr_blue">bright ideas </span>
                  <br />
                  into brilliant labels.
                </h1>
                <Link className="btn_ mt-4" to="/products">
                  Explore Products
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
