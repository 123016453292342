import React, { createContext, useReducer } from "react";
import categoriesInitialState from "./initialstates/categoriesInitialState";
import enquiryInitialState from "./initialstates/enquiryInitialState";
import productsInitialState from "./initialstates/productsInitialState";
import categories from "./reducers/categories";
import enquiry from "./reducers/enquiry";
import products from "./reducers/products";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [categoriesState, categoriesDispatch] = useReducer(
    categories,
    categoriesInitialState
  );

  const [productsState, productsDispatch] = useReducer(
    products,
    productsInitialState
  );

  const [addEnquiryState, addEnquiryDispatch] = useReducer(
    enquiry,
    enquiryInitialState
  );

  return (
    <GlobalContext.Provider
      value={{
        categoriesState,
        categoriesDispatch,
        productsState,
        productsDispatch,
        addEnquiryState,
        addEnquiryDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
