import { useEffect, useState } from "react";
import Images from "../../images";

// used to display images with .png .jpg formats. Not suitable for .svg files
const ImageWithFallback = ({
  fallback = Images.logo_icon_grey,
  alt = "#",
  src,
  ...props
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  return (
    <img
      alt={alt}
      onError={setError}
      src={error ? fallback : src}
      {...props}
    />
  );
};

export default ImageWithFallback;
