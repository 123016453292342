import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import About from "../components/body/about/About";
import Products from "../components/body/products/Products";
import Testimonial from "../components/body/testimonial/Testimonial";
import getCategories from "../context/actions/getCategories";
import { GlobalContext } from "../context/Provider";

function Home() {
  const { categoriesDispatch, categoriesState } = useContext(GlobalContext);
  const history = useHistory();
  useEffect(() => {
    getCategories(history)(categoriesDispatch);
  }, []);

  return (
    <>
      <Products state={categoriesState} />
      <About />
      <Testimonial />
    </>
  );
}

export default Home;
