import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function Filter({
  state: {
    categories: { loading, error, data },
  },
  setFilteredCategories,
  setFilteredBrand,
  filteredCategories,
  setFilterSearchValue,
}) {
  const history = useHistory();

  return (
    <>
      <div className="col-lg-4 col-md-12">
        <button
          className="mb-4 bg-secondary border-0 w-100 rounded p-3 font-weight font-weight-bold filter_btn d-lg-none d-md-block"
          data-toggle="collapse"
          data-target="#filter"
          style={{ color: "#fff" }}
        ></button>
        <div className="border filter mb-5 mb-lg-0" id="filter">
          <div className="p-4 border-bottom">
            <h4>Search</h4>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search Your Products..."
              onChange={(e) => {
                // history.push({
                //     pathname: '/products',
                //     search: `?product=${e.target.value}`,
                //     state: { category: filteredCategories }
                // })
                setFilterSearchValue(e.target.value);
              }}
            />
          </div>
          <div className="bg-light p-4 border-bottom">
            <h4 className="mb-3">Category</h4>
            {loading && (
              <>
                <p>Loading...</p>
              </>
            )}
            {data.status &&
              data.category &&
              data.category.map((cat) => {
                var checked = filteredCategories.map((v) => {
                  if (v == cat.id) {
                    return true;
                  } else {
                    return false;
                  }
                });
                return (
                  <div className="cat_link">
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      defaultChecked={checked[0]}
                      name={cat.id}
                      value={cat.id}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setFilteredCategories([
                            ...filteredCategories,
                            cat.id,
                          ]);
                        } else {
                          setFilteredCategories(
                            filteredCategories.filter((item) => item !== cat.id)
                          );
                        }
                      }}
                    />
                    <label for="links1">{cat.name}</label>
                  </div>
                );
              })}
          </div>
          <div className="p-4">
            <h4>Brands</h4>
            <div className="brands">
              {loading && (
                <>
                  <p>Loading...</p>
                </>
              )}
              <div className="brand-badge">
                <input
                  type="radio"
                  style={{ cursor: "pointer" }}
                  name="flexRadioDefault"
                  value="0"
                  defaultChecked={true}
                  onClick={(e) => {
                    if (e.target.value) {
                      setFilteredBrand(e.target.value);
                    }
                  }}
                />
                <label for="flexRadioDefault1">All</label>
              </div>
              {data.status &&
                data.brands.map((brand) => {
                  return (
                    <>
                      <div className="brand-badge">
                        <input
                          type="radio"
                          style={{ cursor: "pointer" }}
                          name="flexRadioDefault"
                          value={brand.id}
                          onClick={(e) => {
                            if (e.target.value) {
                              setFilteredBrand(e.target.value);
                            }
                          }}
                        />
                        <label for="flexRadioDefault1">{brand.name}</label>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
