import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { productImageUrl } from "../../constants/api";

function ProductSilderImage(props) {
  const { images } = props;
  const [productImages, setProductImages] = useState([]);
  useEffect(() => {
    setProductImages(images);
  }, [images]);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = [];
  let slider2 = [];

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };
  var settingsNav = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };
  return (
    <>
      <div className="col-md-6 col-sm-12">
        <div className="slider-for">
          <Slider
            {...settings}
            asNavFor={nav2}
            ref={(slider) => (slider1 = slider)}
          >
            {images &&
              images.map((img) => {
                return (
                  <img
                    className="img-fluid rounded border"
                    src={productImageUrl + img}
                  />
                );
              })}
          </Slider>
        </div>
        <div className="slider-nav mt-4" style={{ margin: "0 -10px" }}>
          <Slider
            {...settingsNav}
            asNavFor={nav1}
            ref={(slider) => (slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {productImages.length > 2 &&
              productImages.map((img) => {
                return (
                  <div>
                    <img
                      className="img-fluid rounded border mx-2"
                      src={productImageUrl + img}
                    />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ProductSilderImage;
