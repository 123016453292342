import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import SkeltonLoaderHorizontal from "../../skeltonloader/skeltonLoaderHorizontal";

function ProductList({
  state: {
    products: { loading, error, data },
  },
  filteredCategories,
  filteredBrand,
  filterSearchValue,
}) {
  const [filteredData, setFilteredData] = useState([]);
  //Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(5);

  let indexOfLastItem = currentPage * itemPerPage;
  let indexOfFirstItem = indexOfLastItem - itemPerPage;

  let showPageData =
    filteredData && filteredData.slice(indexOfFirstItem, indexOfLastItem);

  let filterData = () => {
    let listData =
      data.status &&
      data.products.filter((item) => {
        if (
          filterSearchValue != "" ||
          filteredCategories.length > 0 ||
          filteredBrand
        ) {
          console.log(filteredBrand);
          if (
            filteredCategories.length > 0 &&
            filteredBrand == 0 &&
            filterSearchValue != ""
          ) {
            if (filteredCategories.includes(item.category)) {
              if (
                item.name
                  .toLowerCase()
                  .includes(filterSearchValue.toLowerCase())
              ) {
                return item;
              }
            }
          } else if (
            filteredCategories.length > 0 &&
            filteredBrand > 0 &&
            filterSearchValue != ""
          ) {
            if (filteredCategories.includes(item.category)) {
              if (
                item.name
                  .toLowerCase()
                  .includes(filterSearchValue.toLowerCase())
              ) {
                return item.brand == filteredBrand;
              }
            }
          } else {
            if (filteredCategories.includes(item.category)) {
              return item;
            }
            if (filteredBrand) {
              if (filteredBrand == 0) {
                return item;
              } else {
                return item.brand == filteredBrand;
              }
            }
            if (filterSearchValue != "") {
              if (
                item.name
                  .toLowerCase()
                  .includes(filterSearchValue.toLowerCase())
              ) {
                return item;
              }
            }
          }
        } else {
          if (filterSearchValue != "") {
            if (
              item.name.toLowerCase().includes(filterSearchValue.toLowerCase())
            ) {
              return item;
            }
          } else {
            return item;
          }
        }
      });
    setFilteredData(listData);
  };

  useEffect(() => {
    filterData();
  }, [data, filterSearchValue, filteredCategories, filteredBrand]);

  // useEffect(() => {
  //     setSearchValue(queryString.parse(location.search))
  // }, [location]);

  return (
    <>
      <div className="col-lg-8 col-md-12 d-flex flex-column">
        {loading && (
          <>
            <SkeltonLoaderHorizontal />
          </>
        )}

        {showPageData && showPageData.length != 0
          ? showPageData.map((pro) => {
              let {
                id,
                name,
                short_description,
                artikelnumber,
                oem,
                ean,
                image,
              } = pro;
              return (
                <div>
                  <div className="product_card mb-4 row align-items-center p-2 bg-white shadow-sm rounded border">
                    <div className="image product-img-frame col-sm-4">
                      <img
                        className="w-100 h-100 object-fit-contain rounded"
                        src={image && data.products_image_base_url + image}
                        width="300"
                      />
                    </div>
                    <div className="content_pr py-4 col-sm-8">
                      <h6 style={{ minHeight: "58px" }}>
                        {name.length > 30
                          ? name.substring(0, 30) + "..."
                          : name}
                      </h6>
                      <p style={{ minHeight: "42px" }}>
                        {short_description.length > 80
                          ? short_description.substring(0, 80) + "..."
                          : short_description}
                      </p>
                      <div className="d-flex align-items-center flex-wrap m-n1">
                        {artikelnumber ? (
                          <span className="badge badge-success p-2 m-1">
                            Artikelnumber : {artikelnumber}
                          </span>
                        ) : (
                          ""
                        )}
                        {oem ? (
                          <span className="badge badge-success p-2 m-1">
                            OEM : {oem}
                          </span>
                        ) : (
                          ""
                        )}
                        {ean ? (
                          <span className="badge badge-success p-2 m-1">
                            EAN : {ean}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <Link
                        className="btn_ mt-4 float-right mr-4"
                        to={{
                          pathname: `/product_details/${id}`,
                          state: pro,
                        }}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          : !loading && (
              <div className="product_card mb-4 d-sm-flex align-items-center p-2 bg-white shadow-sm rounded border">
                <div className="content_pr py-4 text-canter">
                  <h6>No Data Found</h6>
                  <p></p>
                </div>
              </div>
            )}
        <div className="mt-auto">
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemPerPage={itemPerPage}
            filteredData={filteredData}
          ></Pagination>
        </div>
      </div>
    </>
  );
}

export default ProductList;
