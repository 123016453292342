import React from "react";

const SkeltonLoaderHorizontal = () => {
  return (
    <>
      <div className="product_card mb-4 align-items-left p-2 bg-white shadow-sm rounded border">
        <div class="row ">
          <div class="col-md-5">
            <div className="shimmerBG media_h w-100"></div>
          </div>
          <div class="col-md-7 px-3">
            <div class="card-block px-3">
              <h6 className="shimmerBG title-line"></h6>
              <div class="shimmerBG title-line end"></div>
              <div class="shimmerBG content-line m-t-24"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line end"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeltonLoaderHorizontal;
