import React, { useEffect, useState } from "react";
import Images from "../../../images";
import { Link, NavLink } from "react-router-dom";
import { whatsAppNumberApp } from "../../../constants/config";
function Navbar({
  state: {
    categories: { loading, error, data },
  },
}) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setCategories(data.category);
  }, [data]);

  let whatsAppNumber = data.whatsapp_number
    ? data.whatsapp_number
    : whatsAppNumberApp;

  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-3">
          {/* <img className="img-fluid" src={Images.logo} alt={"Impact Logo"} width="180" /> */}
          <Link to="/">
            <img
              className="img-fluid"
              src={Images.impact}
              alt={"Impact Logo"}
              width="180"
            />
          </Link>
        </div>
        <div className="col-sm-6 col-md-9 d-flex align-items-center justify-content-end">
          <div className={`menu_links ${isActive ? "active" : null}`}>
            <div className="overlay_menu d-lg-flex align-items-center">
              <ul className="d-lg-flex align-items-center font-weight-bold mr-5">
                <li className="p-3">
                  <NavLink activeClassName="active" exact to="/">
                    Home
                  </NavLink>
                </li>
                <li className="p-3">
                  <NavLink activeClassName="active" exact to="/about">
                    About
                  </NavLink>
                </li>
                <li className="p-3 dropdown">
                  <a
                    className="dropdown-toggle"
                    href="javscript:void(0);"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                  >
                    Products
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-right dropdown-menu-lg-left pt-0 pb-3 shadow border"
                    aria-labelledby="dropdownMenu2"
                  >
                    {categories &&
                      categories.map((cat) => {
                        return (
                          <li className="px-3 pt-3">
                            <Link to={`/products/${cat.id}`}>{cat.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li className="p-3">
                  <NavLink activeClassName="active" exact to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
              <a
                className="wp font-weight-bold"
                href={whatsAppNumber ? `http://wa.me/${whatsAppNumber}` : "#"}
                target="_blank"
              >
                <img
                  className="img-fluid mr-2"
                  src={Images.whatsAppLogo}
                  alt="Whatsapp"
                />
                {whatsAppNumber}
              </a>
            </div>
          </div>
          <button
            className={`menu d-lg-none ${isActive ? "opened" : null}`}
            area-expanded={isActive}
            aria-label="Main Menu"
            onClick={toggleClass}
          >
            <svg viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
