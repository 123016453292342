import React from "react";
import Images from "../../../images";
import Slider from "react-slick";
function Testimonial() {
  const testimonials = [
    {
      name: "Ijas",
      image: Images.avatar,
      quote:
        "Mi sed adipiscing massa vitae, amet ut in et. In et ac pellentesque fermentum accumsan massa id ornare mattis. Donec amet purus augue odio augue odio consequat nunc, feugiat.",
    },
    {
      name: "Namshad",
      image: Images.avatar,
      quote:
        "Mi sed adipiscing massa vitae, amet ut in et. In et ac pellentesque fermentum accumsan massa id ornare mattis. Donec amet purus augue odio augue odio consequat nunc, feugiat.",
    },
  ];

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <section className="testimonial position-relative">
        <div className="container position-relative">
          <div className="row">
            <div className="col-sm-12">
              <div className="slider m-auto">
                <Slider {...settings}>
                  {testimonials.map((item, i) => {
                    return (
                      <div key={i} className="slide">
                        <p className="mb-5">{item.quote}</p>
                        <div className="d-flex align-items-center">
                          <img
                            className="img-fluid shadow-lg rounded-circle mr-4"
                            src={item.image}
                          />
                          <strong>
                            <span className="d-block">Client</span>
                            {item.name}
                          </strong>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
