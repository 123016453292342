import React from "react";
import Images from "../../../images";

function Banner() {
  return (
    <>
      <div className="subpage_banner">
        <div className="container">
          <div className="row">
            <div className="col mt-n5 position-relative">
              <div className="banner_">
                <h1>Products</h1>
                <span>Best Service, Right Time, Right People</span>
              </div>
              <img
                className="img-fluid"
                src={Images.product_banner_1}
                alt="#"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
