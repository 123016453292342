import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductDescription from "../components/product_details/ProductDescription";
import ProductName from "../components/product_details/ProductName";
import ProductSilderImage from "../components/product_details/ProductSilderImage";

function ProductDetailsPage() {
  const { state } = useLocation();
  const [product, setProduct] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [productDescription, setProductDescription] = useState("");
  useEffect(() => {
    setProduct(state);
    setProductImages([state.image, ...state.images]);
    setProductDescription(state.description);
  }, [state]);

  return (
    <>
      <div class="product_detail mt-md-n5 mt-5">
        <div class="container">
          <div class="row">
            <ProductSilderImage images={productImages} />
            <ProductName product={product} />
            <ProductDescription product_description={productDescription} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetailsPage;
