import React, { useContext, useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import submitEnquiry from "../../context/actions/submitEnquiry";
import { GlobalContext } from "../../context/Provider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import getProducts from "../../context/actions/getProducts";

toast.configure();
function ProductEnquiryForm(props) {
  const history = useHistory();
  const {
    productsDispatch,
    productsState: {
      products: {
        loading: productLoading,
        error: productError,
        data: productData,
      },
    },
  } = useContext(GlobalContext);
  useEffect(() => {
    getProducts(history)(productsDispatch);
  }, []);

  const handleClose = () => props.setShow(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [status, setStatus] = useState(false);
  const {
    addEnquiryDispatch,
    addEnquiryState: {
      enquiry: { loading, error, data },
    },
  } = useContext(GlobalContext);

  const onSubmit = (data) => {
    submitEnquiry(data)(addEnquiryDispatch);
    setStatus(true);
  };

  if (data?.status && status) {
    setStatus(false);
    handleClose();
  }

  useEffect(() => {
    if (status) {
      toast("Enquiry Submitted Successfully✔", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [status]);

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title px-2" id="staticBackdropLabel">
              Product Enquiry
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              {props.dropdown && (
                <select
                  {...register("product")}
                  required
                  data-live-search="true"
                  name="product"
                  className="form-control selectpicker mb-4"
                >
                  <option value="">Select Product</option>
                  {productData.status &&
                    productData.products.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              )}
              {!props.dropdown && props.product && (
                <>
                  <span className="badge badge-success px-2 pt-2 pb-1 mb-2">
                    Selected Product
                  </span>
                  <h6 className="mb-4">{props.product.name}</h6>
                  <input
                    type="hidden"
                    value={props.product.id}
                    {...register("product")}
                    id=""
                  />
                </>
              )}
              <input
                type="hidden"
                value="0"
                {...register("enquiry_message")}
                id=""
              />
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  name="name"
                  {...register("name", {
                    required: true,
                    maxLength: { value: 20, message: "Maximum Limit exceeds" },
                  })}
                  id=""
                />
                {errors.name?.type === "required" && (
                  <span style={{ color: "red" }}>First name is required</span>
                )}
              </div>
              <div className="form-group">
                <label>Email ID</label>
                <input
                  type="email"
                  name="email"
                  {...register("email", {
                    required: "Email Required",
                    pattern: {
                      value:
                        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                      message: "Enter Valid Email",
                    },
                  })}
                  id=""
                />
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email.message}</span>
                )}
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  {...register("phone", {
                    required: "Phone Number Required",
                    maxLength: {
                      value: 10,
                      message: "Please Enter Valid Phone Number",
                    },
                    pattern: {
                      value: /\d+/,
                      message: "Please Enter Valid Number",
                    },
                  })}
                  id=""
                />
                {errors.phone && (
                  <span style={{ color: "red" }}>{errors.phone.message}</span>
                )}
                {/* <input type="text" name="phone" value={value.phone} onChange={handleChange} id="" /> */}
              </div>
              <div className="form-group">
                <label>Place</label>
                <input
                  type="text"
                  name="place"
                  {...register("place", { required: true })}
                  id=""
                />
                {errors.place?.type === "required" && (
                  <span style={{ color: "red" }}>Place is required</span>
                )}
              </div>
              <div className="form-group">
                <label>Additional Information</label>
                <textarea
                  name="message"
                  {...register("message")}
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="form-group">
                <button
                  className="btn_fl"
                  disabled={loading}
                  type="submit"
                  onClick={(event) => {
                    // event.preventDefault();
                    // onSubmit()
                  }}
                >
                  {!loading ? "Send Enquiry" : "Sending ..."}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductEnquiryForm;
