import axios from "axios";

export default (history = null) => {
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  let headers = {
    tokenvalid:
      "Token 8n@R6WxJs&ck+f&<9n?Y!wA+Gm5#^dS>;.=:C-K8bcz~s;8%.Jc8N<Nq8ch!w7e@d62HB*YC8tDU%k!+Y67fLU,~+MCLENcwr6+y+)*Wj@aSK8Cq:Xes4wr@*7=#@y#EbBsUNnpJh=x)en^p,yx%&}Z<]F!Q#bU#Tv#ttR7mm#}A&7sj-~[rER.G#Hb`>sAbLj7BG@P~xp8hk>N2W!#c;<b}-#vY@[(P-#H]ty;#e@WcgkY7UA3Z.p@6#9x;n3FZ",
  };

  // if (localStorage.token) {
  //     headers.tokenvalid = `Token ${localStorage.token}`;
  // }

  const axiosInstance = axios.create({
    baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (error.response.status === 403) {
        console.log("403 Error");
        // localStorage.removeItem("token");

        if (history) {
          // history.push('/')
        } else {
          // window.location = "/"
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );
  return axiosInstance;
};
