import Images from "../images";

function AboutPage() {
  return (
    <>
      <div className="subpage_banner">
        <div className="container">
          <div className="row">
            <div className="col mt-n5 position-relative">
              <div className="banner_">
                <h1>About Us</h1>
                <span>Best Service, Right Time, Right People</span>
              </div>
              <img className="img-fluid" src={Images.about_banner} alt="#" />
            </div>
          </div>
        </div>
      </div>
      <div className="about_page_content py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="lg_fnt">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea.
              </p>
            </div>
            <div className="col-md-7 col-sm-12 mt-4">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="col-md-5 col-sm-12">
              <img className="img-fluid" src={Images.about} alt="#" />
            </div>
            <div className="col-sm-12 col-md-6 mt-3">
              <div className="about_card">
                <div className="icon_box">
                  <img src={Images.like} alt="#" />
                </div>
                <h2>Vision</h2>
                <p>
                  Voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-3">
              <div className="about_card">
                <div className="icon_box">
                  <img src={Images.printer} alt="#" />
                </div>
                <h2>Mission</h2>
                <p>
                  Voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
