import React, { useState } from "react";
import Images from "../../../images";
import ProductEnquiryForm from "../../product_details/ProductEnquiryForm";
function Stats() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <ProductEnquiryForm dropdown={true} show={show} setShow={setShow} />
      <div className="stats">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-4">
                COMPLETE LINEUP OF ORIGINAL AND COMPATIBLE IMAGING SUPPLIES
              </h2>
              <div className="row align-items-center">
                <div className="col-lg-9 col-md-12">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 d-flex align-items-center">
                      <div className="icon_">
                        <img src={Images.laser} alt="#" />
                      </div>
                      <strong>
                        1M+<span>Parts Produced</span>
                      </strong>
                    </div>
                    <div className="col-md-4 col-sm-12 d-flex align-items-center mt-3 mt-md-0 mt-lg-0">
                      <div className="icon_">
                        <img src={Images.printer} alt="#" />
                      </div>
                      <strong>
                        69<span>Machines In-House</span>
                      </strong>
                    </div>
                    <div className="col-md-4 col-sm-12 d-flex align-items-center mt-3 mt-md-0 mt-lg-0">
                      <div className="icon_">
                        <img src={Images.printer} alt="#" />
                      </div>
                      <strong>
                        7500+<span>Customers Served</span>
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 text-md-center text-lg-right mt-lg-0 mt-5 mt-lg-0">
                  <button className="btn_fl" onClick={handleShow}>
                    Get Instant Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
