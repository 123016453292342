import React from "react";
import Banner from "./banner/Banner";
import Navbar from "./navbar/Navbar";
import Stats from "./stats/Stats";
import { useLocation } from "react-router-dom";

function Header({ categoriesState }) {
  const { pathname } = useLocation();

  return (
    <header className="pt-4">
      <Navbar state={categoriesState} />
      {pathname === "/" && <Banner />}
      {pathname === "/" && <Stats />}
    </header>
  );
}

export default Header;
