import React from "react";
import { Link } from "react-router-dom";
import {
  emailApp,
  facebookLink,
  instagramLink,
  linkedinLink,
  locationIndia,
  locationKsa,
  phoneNumberApp,
  twitterLink,
} from "../../constants/config";
import Images from "../../images";

function Footer() {
  let phoneNumber = phoneNumberApp;
  return (
    <footer className="position-relative">
      <div className="container">
        <div className="row">
          <div className="col">
            <h6 className="mb-5">Get in Touch!</h6>
            <div className="row address">
              <div className="col-sm-4">
                <span>Phone Hotline</span>
                <a
                  className="wp"
                  href={phoneNumber ? `tel:${phoneNumber}` : "#"}
                >
                  {" "}
                  {phoneNumber}{" "}
                </a>
              </div>
              <div className="col-sm-4 mt-3 mt-sm-0">
                <span>Mail Us</span>
                <a href={`mailto:${emailApp}`} target="_blank">
                  {" "}
                  {emailApp}{" "}
                </a>
              </div>
              <div className="col-sm-4 mt-3 mt-sm-0">
                <span>Locate Us</span>
                <strong className="d-flex">
                  <a className="mr-2" href={locationIndia} target="_blank">
                    India
                  </a>
                  <a href={locationKsa} target="_blank">
                    , KSA
                  </a>
                </strong>
              </div>
            </div>
            <div className="d-sm-flex align-items-center mt-5">
              <ol className="d-flex mr-5">
                <li className="mr-3">
                  <a href={facebookLink} target="_blank">
                    <img src={Images.fb} alt="#" />
                  </a>
                </li>
                <li className="mr-3">
                  <a href={twitterLink} target="_blank">
                    <img src={Images.tw} alt="#" />
                  </a>
                </li>
                <li className="mr-3">
                  <a href={instagramLink} target="_blank">
                    <img src={Images.ig} alt="#" />
                  </a>
                </li>
                <li>
                  <a href={linkedinLink} target="_blank">
                    <img src={Images.in} alt="#" />
                  </a>
                </li>
              </ol>
              <ul className="d-flex align-items-center font-weight-bold mr-sm-5 mt-3 mt-sm-0">
                <li className="pr-3">
                  <Link to="/">Home</Link>
                </li>
                <li className="p-3">
                  <Link to="/about">About</Link>
                </li>
                <li className="p-3">
                  <Link to="/products">Products</Link>
                </li>
                <li className="p-3">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <p className="mt-5">Copyright &copy; 2021 All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
