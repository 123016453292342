import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { emailApp, phoneNumberApp } from "../constants/config";
import submitEnquiry from "../context/actions/submitEnquiry";
import { GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Images from "../images";

function ContactPage() {
  let phoneNumber = phoneNumberApp;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [status, setStatus] = useState(false);
  const {
    addEnquiryDispatch,
    addEnquiryState: {
      enquiry: { loading, error, data },
    },
  } = useContext(GlobalContext);

  const onSubmit = (data) => {
    submitEnquiry(data)(addEnquiryDispatch);
    setStatus(true);
  };

  if (data?.status && status) {
    setStatus(false);
  }

  useEffect(() => {
    if (status) {
      toast("Message send Successfully✔", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [status]);

  return (
    <>
      <div className="subpage_banner">
        <div className="container">
          <div className="row">
            <div className="col mt-n5 position-relative">
              <div className="banner_">
                <h1>Contact</h1>
                <span>Best Service, Right Time, Right People</span>
              </div>
              <img className="img-fluid" src={Images.product_banner} alt="#" />
            </div>
          </div>
        </div>
      </div>
      <div className="contact_page py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <p className="lg_fnt">
                please fill in the form below and we will contact you within 48
                hours.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="hidden"
                  value="1"
                  {...register("enquiry_message")}
                  id=""
                />
                <input type="hidden" value="0" {...register("product")} id="" />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="name"
                      {...register("name", {
                        required: true,
                        maxLength: {
                          value: 20,
                          message: "Maximum Limit exceeds",
                        },
                      })}
                      id=""
                    />
                    {errors.name?.type === "required" && (
                      <span style={{ color: "red" }}>
                        First name is required
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      {...register("email", {
                        required: "Email Required",
                        pattern: {
                          value:
                            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                          message: "Enter Valid Email",
                        },
                      })}
                      id=""
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      {...register("phone", {
                        required: "Phone Number Required",
                        maxLength: {
                          value: 10,
                          message: "Please Enter Valid Phone Number",
                        },
                        pattern: {
                          value: /\d+/,
                          message: "Please Enter Valid Number",
                        },
                      })}
                      id=""
                    />
                    {errors.phone && (
                      <span style={{ color: "red" }}>
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Place</label>
                    <input
                      type="text"
                      name="place"
                      {...register("place", { required: true })}
                      id=""
                    />
                    {errors.place?.type === "required" && (
                      <span style={{ color: "red" }}>Place is required</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>Your Message</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      {...register("message")}
                    ></textarea>
                  </div>
                </div>
                <button
                  className="btn_fl mt-3"
                  disabled={loading}
                  type="submit"
                >
                  {!loading ? "Send Message" : "Sending ..."}
                </button>
              </form>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="bg-light p-5 rounded mt-5 mt-lg-0">
                <h4>Get In Touch!</h4>
                <address>
                  <b>DAMMAM</b>
                  IMPACT SYSTEMS TRADING COMPANY.
                  <br />
                  Tel no: 013 8282994, 8282995
                  {/* Fax: 013  8050264 */}
                </address>
                <address>
                  <b>DAMMAM</b>
                  IMPACT SYSTEMS TRADING COMPANY.
                  <br />
                  Adama, 13th Street Ameer Bin Nazer Old, Dammam
                </address>
                <span>Phone Hotline</span>
                <a className="wp" href={`tel:${phoneNumber}`}>
                  {" "}
                  {phoneNumber}{" "}
                </a>
                <span className="mt-3">Mail Us</span>
                <a href={`mailto:${emailApp}`} target="_blank">
                  {" "}
                  {emailApp}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
