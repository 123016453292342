import React from "react";

const SkeltonLoader = () => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6 py-3">
        <div className="bg-white  product">
          <div className="shimmerBG media"></div>
          <div className="p-10">
            <div className="shimmerBG title-line"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeltonLoader;
