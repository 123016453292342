import React, { useState } from "react";
import { Link } from "react-router-dom";
import Images from "../../../images";
import ProductEnquiryForm from "../../product_details/ProductEnquiryForm";

function About() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <section className="about">
      <ProductEnquiryForm dropdown={true} show={show} setShow={setShow} />
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 mb-5 mb-lg-0">
            <h4 className="mb-3">Who we are</h4>
            <p className="lg_fnt">
              Consectetur adipiscing elit. Amet, odio arcu, nunc ornare viverra.
              At vitae semper purus, aliquet diam feugiat morbi. Interdum non
              metus sed lacus, ut. Id bibendum quis nec sagittis in elit magna
              nullam. Dolor pellentesque rhoncus et amet tellus non velit.
            </p>
            <p>
              Velit hendrerit nisi, fermentum eros. Vitae nec, vitae eget hac.
              Sit neque, fermentum tellus libero lectus euismod dignissim.
              Hendrerit praesent nibh nulla laoreet elit. Tincidunt nibh leo
              iaculis interdum cursus. Convallis ullamcorper morbi arcu congue
              ullamcorper. Vel turpis quis scelerisque duis tellus erat
              volutpat. Blandit lacinia in quis tortor egestas.
            </p>
            <div className="d-fex align-items-center mt-5">
              <button className="btn_fl mr-4" onClick={handleShow}>
                Get Instant Quote
              </button>
              {/* <a className="btn_fl mr-4" href="">Get Instant Quote</a> */}
              <Link className="btn_" to="/about">
                Explore About
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <img className="img-fluid" src={Images.whoWe} alt="#" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
